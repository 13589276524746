import { cn } from "@/lib/utils/cssUtils";
import { ClassValue } from "clsx";
import { ComponentProps } from "react";
interface CustomTableProps extends ComponentProps<"table"> {
  containerClassName?: ClassValue;
}
const Table = ({
  className,
  containerClassName,
  ...props
}: CustomTableProps) => <div className={cn("relative flex w-full flex-shrink flex-grow basis-0 flex-col overflow-auto rounded border", containerClassName)} data-sentry-component="Table" data-sentry-source-file="table.tsx">
    <table className={cn("w-full caption-bottom text-sm", className)} {...props} />
  </div>;
Table.displayName = "Table";
const TableHeader = ({
  className,
  ...props
}: ComponentProps<"thead">) => <thead className={cn("[&_tr]:border-b", "sticky top-0 z-[1] bg-gray-100", className)} {...props} data-sentry-component="TableHeader" data-sentry-source-file="table.tsx" />;
TableHeader.displayName = "TableHeader";
const TableBody = ({
  className,
  ...props
}: ComponentProps<"tbody">) => <tbody className={cn("[&_tr:last-child]:border-0", className)} {...props} data-sentry-component="TableBody" data-sentry-source-file="table.tsx" />;
TableBody.displayName = "TableBody";
const TableFooter = ({
  className,
  ...props
}: ComponentProps<"tfoot">) => <tfoot className={cn("bg-muted/50 border-t font-medium [&>tr]:last:border-b-0", className)} {...props} data-sentry-component="TableFooter" data-sentry-source-file="table.tsx" />;
TableFooter.displayName = "TableFooter";
const TableRow = ({
  className,
  ...props
}: ComponentProps<"tr">) => <tr className={cn("hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors", className)} {...props} data-sentry-component="TableRow" data-sentry-source-file="table.tsx" />;
TableRow.displayName = "TableRow";
const TableHead = ({
  className,
  ...props
}: ComponentProps<"th">) => <th className={cn("h-10 text-left align-middle font-medium text-component-fg [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]", className)} {...props} data-sentry-component="TableHead" data-sentry-source-file="table.tsx" />;
TableHead.displayName = "TableHead";
const TableCell = ({
  className,
  ...props
}: ComponentProps<"td">) => <td className={cn("p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]", className)} {...props} data-sentry-component="TableCell" data-sentry-source-file="table.tsx" />;
TableCell.displayName = "TableCell";
const TableCaption = ({
  className,
  ...props
}: ComponentProps<"caption">) => <caption className={cn("mt-4 text-sm text-component-fg", className)} {...props} data-sentry-component="TableCaption" data-sentry-source-file="table.tsx" />;
TableCaption.displayName = "TableCaption";
export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };