import userAPI from "@/api/userAPI";
import { useQuery } from "@tanstack/react-query";

const useUserQuery = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: userAPI.getUser,
    staleTime: Infinity,
  });

export default useUserQuery;
