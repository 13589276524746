import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { Controller, ControllerProps, FieldPath, FieldValues, FormProvider, useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils/cssUtils";
import { Label } from "@/components/ui/label";
import { createContext, useContext, useId, ComponentProps } from "react";
const Form = FormProvider;
type FormFieldContextValue<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
  name: TName;
};
const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue);
const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return <FormFieldContext.Provider value={{
    name: props.name
  }} data-sentry-element="unknown" data-sentry-component="FormField" data-sentry-source-file="form.tsx">
      <Controller {...props} data-sentry-element="Controller" data-sentry-source-file="form.tsx" />
    </FormFieldContext.Provider>;
};
const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const {
    getFieldState,
    formState
  } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }
  const {
    id
  } = itemContext;
  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};
type FormItemContextValue = {
  id: string;
};
const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue);
const FormItem = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  const id = useId();
  return <FormItemContext.Provider value={{
    id
  }} data-sentry-element="unknown" data-sentry-component="FormItem" data-sentry-source-file="form.tsx">
      <div className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>;
};
FormItem.displayName = "FormItem";
const FormLabel = ({
  className,
  ...props
}: ComponentProps<typeof LabelPrimitive.Root>) => {
  const {
    error,
    formItemId
  } = useFormField();
  return <Label className={cn(error && "text-destructive", className)} htmlFor={formItemId} {...props} data-sentry-element="Label" data-sentry-component="FormLabel" data-sentry-source-file="form.tsx" />;
};
FormLabel.displayName = "FormLabel";
const FormControl = ({
  ...props
}: ComponentProps<typeof Slot>) => {
  const {
    error,
    formItemId,
    formDescriptionId,
    formMessageId
  } = useFormField();
  return <Slot id={formItemId} aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`} aria-invalid={!!error} {...props} data-sentry-element="Slot" data-sentry-component="FormControl" data-sentry-source-file="form.tsx" />;
};
FormControl.displayName = "FormControl";
const FormDescription = ({
  className,
  ...props
}: ComponentProps<"p">) => {
  const {
    formDescriptionId
  } = useFormField();
  return <p id={formDescriptionId} className={cn("text-[0.8rem] text-primary-fg-body", className)} {...props} data-sentry-component="FormDescription" data-sentry-source-file="form.tsx" />;
};
FormDescription.displayName = "FormDescription";
const FormMessage = ({
  className,
  children,
  ...props
}: ComponentProps<"p">) => {
  const {
    error,
    formMessageId
  } = useFormField();
  const body = error ? String(error?.message) : children;
  if (!body) {
    return null;
  }
  return <p id={formMessageId} className={cn("text-[0.8rem] font-medium text-destructive", className)} {...props} data-sentry-component="FormMessage" data-sentry-source-file="form.tsx">
      {body}
    </p>;
};
FormMessage.displayName = "FormMessage";
export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };