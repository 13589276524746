import { cn } from "@/lib/utils/cssUtils";
import { cva, type VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";
const textareaVariants = cva("text-component-fg border-input placeholder:text-placeholder-fg focus-visible:ring-ring flex min-h-[60px] w-full rounded-md border bg-transparent px-3 py-2 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50", {
  variants: {
    variant: {
      default: "bg-primary",
      form: "cursor-text bg-primary hover:bg-primary-hover focus:bg-primary disabled:cursor-default disabled:bg-primary-hover/50 hover:ring-1 disabled:hover:ring-0"
    },
    defaultVariants: {
      variant: "default"
    }
  }
});
export interface TextareaProps extends ComponentProps<"textarea">, VariantProps<typeof textareaVariants> {}
const Textarea = ({
  className,
  variant,
  ...props
}: TextareaProps) => {
  return <textarea className={cn(textareaVariants({
    variant,
    className
  }))} {...props} data-sentry-component="Textarea" data-sentry-source-file="textarea.tsx" />;
};
Textarea.displayName = "Textarea";
export { Textarea };