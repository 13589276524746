import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon } from "@radix-ui/react-icons";
import { Column } from "@tanstack/react-table";
import { cn } from "@/lib/utils/cssUtils";
import { Button as SCNButton } from "@/components/ui/button";
interface SortableDataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}
export function SortableDataTableColumnHeader<TData, TValue>({
  column,
  title,
  className
}: SortableDataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }
  return <div className={cn("flex items-center space-x-2", className)} data-sentry-component="SortableDataTableColumnHeader" data-sentry-source-file="sortableDataTableColumnHeader.tsx">
      <SCNButton type="button" variant="ghost-no-hover" className={cn("flex size-full justify-between rounded px-4", {
      "bg-slate-300": ["asc", "desc"].includes(column.getIsSorted() as string)
    })} onClick={() => column.toggleSorting()} data-sentry-element="SCNButton" data-sentry-source-file="sortableDataTableColumnHeader.tsx">
        <div className="">{title}</div>
        {column.getIsSorted() === "desc" ? <ArrowDownIcon className="ml-2 size-4" /> : column.getIsSorted() === "asc" ? <ArrowUpIcon className="ml-2 size-4" /> : <CaretSortIcon className="ml-2 size-4" />}
      </SCNButton>
    </div>;
}