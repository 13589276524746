import { Pharmacy } from "@/lib/types/pharmacy";
import customFetch from "./customFetch";

const getPharmaciesElastic = async (query: string): Promise<Pharmacy[]> => {
  const res = await customFetch("/pharmacy/search", { body: { query }, method: "POST" });
  return res.pharmacies.hits.hits.map((v: { _source: Pharmacy }) => v?._source) as Pharmacy[];
};

const getPharmacyById = async (pharmacyId: number): Promise<Pharmacy> => {
  const { pharmacy } = await customFetch(`/pharmacy/${pharmacyId}`);
  return pharmacy;
};

const getPharmacies = async (): Promise<Pharmacy[]> => {
  const { pharmacies } = await customFetch(`/pharmacy`);
  return pharmacies;
};

const createPharmacy = async (pharmacyPayload: Pharmacy) => {
  const { pharmacy } = await customFetch(`pharmacy`, { body: pharmacyPayload, method: "POST" });
  return pharmacy;
};

const updatePharmacy = async (pharmacyPayload: Pharmacy) => {
  const { pharmacy } = await customFetch(`pharmacy`, { body: pharmacyPayload, method: "PUT" });
  return pharmacy;
};

const pharamcyAPI = {
  getPharmaciesElastic,
  getPharmacyById,
  getPharmacies,
  createPharmacy,
  updatePharmacy,
};

export default pharamcyAPI;
