import patientAPI from "@/api/patientAPI";
import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";

const useGlobalPatientMessageQuery = (selectedPatientId: number | null) => {
  return useQuery({
    queryKey: ["selectedPatientGlobalMessage", selectedPatientId],
    queryFn: () => patientAPI.getPatientDataById(selectedPatientId!),
    select: (patientData) => patientData.globalMessage,
    enabled: !isNil(selectedPatientId),
  });
};

export default useGlobalPatientMessageQuery;
