import { PopoverContent, PopoverTrigger, Popover as SCNPopover } from "@/components/ui/popover";
import { Button as SCNButton } from "@/components/ui/button";
import { Input as SCNInput } from "@/components/ui/input";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import Icon from "@mdi/react";
import { mdiCloseCircleOutline, mdiMenuDown } from "@mdi/js";
import { cn } from "@/lib/utils/cssUtils";
import { Dispatch, KeyboardEvent, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { isEmpty, isNil, isString } from "lodash";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ClassValue } from "clsx";
import { DebouncedInput } from "@/components/ui/debouncedInput";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { cva, type VariantProps } from "class-variance-authority";

// TODO: add a style variant like input, textarea etc, especially for forms style and add `disabled:bg-primary-hover/50`

// const comboboxVariants = cva(
//   "border-input placeholder:text-placeholder-fg focus-visible:ring-ring text-component-fg flex h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-default disabled:opacity-50",
//   {
//     variants: {
//       variant: {
//         default: "bg-primary",
//         form: "cursor-text bg-primary hover:bg-primary-hover focus:bg-primary disabled:cursor-default disabled:bg-primary-hover/50 hover:ring-1 disabled:hover:ring-0",
//       },
//       defaultVariants: {
//         variant: "default",
//       },
//     },
//   }
// );

export type ComboItem = {
  name: string | ReactNode;
  value: string;
  tooltip?: string;
  otherData?: unknown;
  displayValue?: string;
};
interface ComboboxProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  currentValue: string | null;
  data: ComboItem[] | null | undefined;
  onSelect?: (value: string) => void;
  onDeselect?: () => void;
  customInputEnabled?: boolean;
  useVirtualization?: boolean;
  allowSearch?: boolean;
  customSearchFn?: (item: ComboItem, searchText: string) => boolean;
  allowRemove?: boolean;
  containerClassName?: ClassValue;
  popoverTriggerClassName?: ClassValue;
  popoverTriggerTextClassName?: ClassValue;
  popoverContentClassName?: ClassValue;
  popoverContentInnerContainerClassName?: ClassValue;
  popoverContentItemClassName?: ClassValue;
  popoverContentSelectedItemClassName?: ClassValue;
  popoverContentItemTextClassName?: ClassValue;
  popoverContentSelectedItemTextClassName?: ClassValue;
  popoverContentUsePortal?: boolean;
  popoverContentAlignment?: "start" | "center" | "end";
  placeholder?: string;
  showTriggerTooltip?: boolean;
  splitTextSearch?: boolean; // TODO: allow searching for ReactNode as well
  onInputKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onSearchKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  dropdownHeight?: number;
  debouncedInputEnabled?: boolean;
  disabled?: boolean;
  virtualizerEstimateSize?: number; // estimate size for height/width of virtualizer items, see tanstack documentation
  virtualizerGap?: number; // gap between virtualizer items
  disableChevron?: boolean;
  hideDropdownWhenEmpty?: boolean;
  showDisplayValue?: boolean;
}
export const Combobox = ({
  open: openProp,
  onOpenChange,
  currentValue,
  data,
  onSelect,
  onDeselect,
  customInputEnabled = false,
  useVirtualization = false,
  allowSearch,
  customSearchFn,
  allowRemove = false,
  containerClassName,
  popoverTriggerClassName,
  popoverTriggerTextClassName,
  popoverContentClassName,
  popoverContentInnerContainerClassName,
  popoverContentItemClassName,
  popoverContentSelectedItemClassName,
  popoverContentItemTextClassName,
  popoverContentSelectedItemTextClassName,
  popoverContentUsePortal = true,
  popoverContentAlignment = "start",
  placeholder,
  showTriggerTooltip = false,
  splitTextSearch = false,
  onInputKeyDown,
  onSearchKeyDown,
  dropdownHeight,
  debouncedInputEnabled = false,
  disabled = false,
  virtualizerEstimateSize = 35,
  virtualizerGap = 0,
  disableChevron = false,
  hideDropdownWhenEmpty = false,
  showDisplayValue = true
}: ComboboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: false,
    onChange: onOpenChange
  });
  const [selectedItem, setSelectedItem] = useState<ComboItem | null>(null);
  useEffect(() => {
    if (!isNil(data) && !isEmpty(currentValue)) {
      const selectedItem = data.find(item => item.value === currentValue);
      if (isNil(selectedItem)) {
        // if the current value isn't in the data, then we add it to the top
        // TODO: this is obviously a hack and we'll have to fix it in combobox2 and need to add the item to `data`
        setSelectedItem({
          value: currentValue,
          name: currentValue
        } as ComboItem);
      } else {
        setSelectedItem(selectedItem);
      }
    } else if (isNil(data) || isEmpty(currentValue)) {
      setSelectedItem(null);
    }
  }, [data, currentValue]);
  useEffect(() => {
    if (open && customInputEnabled) {
      // need a put a timeout here to allow the popover to open first before refocusing on the input
      const timeout = setTimeout(() => inputRef && inputRef.current?.focus(), 500);
      return () => clearTimeout(timeout);
    }
  }, [open]);
  return <>
      <div className={cn("relative flex w-36", containerClassName)}>
        <SCNPopover open={open} onOpenChange={setOpen} data-sentry-element="SCNPopover" data-sentry-source-file="combobox.tsx">
          <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="combobox.tsx">
            <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="combobox.tsx">
              <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="combobox.tsx">
                {customInputEnabled ? <div className={cn("border-input focus-visible:ring-ring flex h-9 w-full justify-between gap-0.5 rounded-md border bg-primary p-0 text-sm text-component-fg shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder-fg hover:bg-primary-hover hover:ring-1 focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:ring-0", popoverTriggerClassName)} onClick={e => {
                e.preventDefault();
              }}>
                    {debouncedInputEnabled ? <DebouncedInput type="text" ref={inputRef} placeholder={placeholder} className={cn("h-full flex-1 overflow-hidden text-ellipsis whitespace-nowrap border-none py-0 pl-3 pr-0 text-component-fg shadow-none outline-none ring-0 placeholder:text-placeholder-fg hover:bg-transparent focus-visible:ring-0 disabled:cursor-default", popoverTriggerTextClassName)} aria-expanded={open} onClick={e => {
                  e.preventDefault();
                  setOpen(prev => !prev);
                }} value={currentValue ?? ""} onChange={e => {
                  onSelect && onSelect(e);
                }} onKeyDown={e => {
                  if (["Enter", "Tab", "Escape"].includes(e.key)) {
                    setOpen(false);
                  }
                }} disabled={disabled} /> : <SCNInput type="text" ref={inputRef} placeholder={placeholder} className={cn("h-full flex-1 overflow-hidden text-ellipsis whitespace-nowrap border-none py-0 pl-3 pr-0 text-component-fg shadow-none outline-none ring-0 placeholder:text-placeholder-fg hover:bg-transparent focus-visible:ring-0 disabled:cursor-default", popoverTriggerTextClassName)} aria-expanded={open} onClick={e => {
                  e.preventDefault();
                  setOpen(prev => !prev);
                }} value={currentValue ?? ""} onChange={e => {
                  onSelect && onSelect(e.target.value);
                }} onKeyDown={e => {
                  onInputKeyDown && onInputKeyDown(e);
                  if (["Enter", "Tab", "Escape"].includes(e.key)) {
                    setOpen(false);
                  }
                }} disabled={disabled} />}
                    <div className="flex h-full items-center justify-end">
                      {allowRemove && !isNil(selectedItem) && <SCNButton variant="ghost-no-hover" className="px-0.5 hover:text-blue-500" onClick={e => {
                    onDeselect && onDeselect();
                  }}>
                          <Icon path={mdiCloseCircleOutline} size={0.75} className="size-4 text-component-fg" />
                        </SCNButton>}
                      {!disableChevron && <SCNButton variant="ghost-no-hover" className="pl-0.5" onClick={e => {
                    setOpen(prev => !prev);
                  }}>
                          <Icon path={mdiMenuDown} size={1} className="h-4 w-4 text-component-fg" />
                        </SCNButton>}
                    </div>
                  </div> : <SCNButton variant="outline" className={cn("relative flex h-9 w-full min-w-10 justify-between gap-0.5 bg-primary py-0 pl-3 pr-2 text-sm shadow-sm hover:ring-1 disabled:opacity-50 disabled:hover:ring-0", popoverTriggerClassName)} aria-expanded={open} disabled={disabled}>
                    <div className={cn("flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-component-fg", isNil(selectedItem) && "text-placeholder-fg", popoverTriggerTextClassName)}>
                      {isNil(selectedItem) ? placeholder ?? "Select..." : showDisplayValue ? selectedItem?.displayValue ?? selectedItem?.name ?? currentValue : selectedItem?.name ?? currentValue}
                    </div>
                    <div className="flex h-full items-center justify-end">
                      {!isNil(selectedItem) && allowRemove && <div onClick={e => {
                    e.stopPropagation();
                    onDeselect && onDeselect();
                    setSelectedItem(null);
                  }} className="px-0.5 text-component-fg hover:text-blue-500">
                          <Icon path={mdiCloseCircleOutline} size={0.75} />
                        </div>}
                      {!disableChevron && <div className="pl-0.5">
                          <Icon path={mdiMenuDown} size={1} className="size-4 text-component-fg" />
                        </div>}
                    </div>
                  </SCNButton>}
              </PopoverTrigger>
            </TooltipTrigger>
            {showTriggerTooltip && !isNil(selectedItem) && <TooltipContent className="">
                {showDisplayValue ? selectedItem?.displayValue ?? selectedItem?.name ?? currentValue ?? "" : selectedItem?.name ?? currentValue ?? ""}
              </TooltipContent>}
          </SCNTooltip>

          <PopoverContent className={cn("z-[300] w-[var(--radix-popover-trigger-width)] p-0", popoverContentClassName)} disableArrow align={popoverContentAlignment} usePortal={popoverContentUsePortal && !customInputEnabled} onOpenAutoFocus={e => {
          allowSearch && e.preventDefault();
        }} data-sentry-element="PopoverContent" data-sentry-source-file="combobox.tsx">
            {useVirtualization ? <CommandComboboxVirtual data={data} currentValue={currentValue} onSelect={e => {
            onSelect && onSelect(e);
          }} setSelectedItem={setSelectedItem} open={open} onPopoverOpenChange={setOpen} allowSearch={allowSearch} customSearchFn={customSearchFn} splitTextSearch={splitTextSearch} onSearchKeyDown={onSearchKeyDown} dropdownHeight={dropdownHeight} popoverContentInnerContainerClassName={popoverContentInnerContainerClassName} popoverContentItemClassName={popoverContentItemClassName} popoverContentSelectedItemClassName={popoverContentSelectedItemClassName} popoverContentItemTextClassName={popoverContentItemTextClassName} popoverContentSelectedItemTextClassName={popoverContentSelectedItemTextClassName} virtualizerEstimateSize={virtualizerEstimateSize} virtualizerGap={virtualizerGap} hideDropdownWhenEmpty={hideDropdownWhenEmpty} /> : <CommandCombobox data={data} currentValue={currentValue} onSelect={e => {
            onSelect && onSelect(e);
          }} setSelectedItem={setSelectedItem} open={open} onPopoverOpenChange={setOpen} allowSearch={allowSearch} customSearchFn={customSearchFn} splitTextSearch={splitTextSearch} onSearchKeyDown={onSearchKeyDown} dropdownHeight={dropdownHeight} popoverContentInnerContainerClassName={popoverContentInnerContainerClassName} popoverContentItemClassName={popoverContentItemClassName} popoverContentSelectedItemClassName={popoverContentSelectedItemClassName} popoverContentItemTextClassName={popoverContentItemTextClassName} popoverContentSelectedItemTextClassName={popoverContentSelectedItemTextClassName} hideDropdownWhenEmpty={hideDropdownWhenEmpty} />}
          </PopoverContent>
        </SCNPopover>
      </div>
    </>;
};
interface CommandComboboxProps {
  data: ComboItem[] | null | undefined;
  currentValue: string | null;
  setSelectedItem: Dispatch<SetStateAction<ComboItem | null>>;
  open: boolean;
  onPopoverOpenChange: (open: boolean) => void;
  onSelect?: (value: string) => void;
  allowSearch?: boolean;
  customSearchFn?: (item: ComboItem, searchText: string) => boolean;
  splitTextSearch: boolean;
  dropdownHeight?: number;
  onSearchKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  popoverContentInnerContainerClassName?: ClassValue;
  popoverContentItemClassName?: ClassValue;
  popoverContentSelectedItemClassName?: ClassValue;
  popoverContentItemTextClassName?: ClassValue;
  popoverContentSelectedItemTextClassName?: ClassValue;
  virtualizerEstimateSize?: number;
  virtualizerGap?: number;
  hideDropdownWhenEmpty: boolean;
}
const CommandCombobox = ({
  data,
  currentValue,
  setSelectedItem,
  open,
  onPopoverOpenChange,
  onSelect,
  allowSearch = false,
  customSearchFn,
  splitTextSearch,
  dropdownHeight = 300,
  onSearchKeyDown,
  popoverContentInnerContainerClassName,
  popoverContentItemClassName,
  popoverContentSelectedItemClassName,
  popoverContentItemTextClassName,
  popoverContentSelectedItemTextClassName,
  hideDropdownWhenEmpty
}: CommandComboboxProps) => {
  const [filteredItems, setFilteredItems] = useState<ComboItem[]>(data ?? []);
  const [searchText, setSearchText] = useState("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleSearch = (searchText: string) => {
    const splitSearchText = searchText.split(" ");
    setFilteredItems(data?.filter(item => customSearchFn ? customSearchFn(item, searchText) : splitTextSearch && isString(item.name) ? splitSearchText.map(s => (item.name as string).toLowerCase().includes(s.toLowerCase())).every(v => v) : (item.name as string).toLowerCase().includes(searchText.toLowerCase())) ?? []);
  };
  useEffect(() => {
    console.log(`open: `, open);
    console.log(`scrollRef: `, scrollRef.current);
    if (open) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView();
      }, 0);
    }
  }, [open]);
  return <Command loop shouldFilter={false} data-sentry-element="Command" data-sentry-component="CommandCombobox" data-sentry-source-file="combobox.tsx">
      {allowSearch && <CommandInput placeholder="Search..." onValueChange={e => {
      setSearchText(e);
      handleSearch(e);
    }} value={searchText} />}

      {hideDropdownWhenEmpty && filteredItems.length === 0 ? <></> : <>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup style={{
        height: `${dropdownHeight}px`,
        overflow: "hidden"
      }}>
            <CommandList className={cn("max-h-[unset]", popoverContentInnerContainerClassName)} style={{
          height: `${dropdownHeight - 8}px`
        }} onWheel={e => {
          e.stopPropagation();
        }} onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (onSearchKeyDown) {
            return onSearchKeyDown(e);
          }
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}>
              {filteredItems.map(item => <CommandItem key={item.value} ref={item.value === currentValue ? scrollRef : undefined} value={item.value} onSelect={() => {
            onSelect && onSelect(item.value);
            setSelectedItem(item);
            onPopoverOpenChange(false);
          }} onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (onSearchKeyDown) {
              return onSearchKeyDown(e);
            }
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }} className={cn("cursor-pointer text-component-fg", item.value === currentValue && "rounded-sm bg-gray-400 text-primary", item.value === currentValue && popoverContentSelectedItemClassName, popoverContentItemClassName)}>
                  <SCNTooltip>
                    <TooltipTrigger asChild>
                      <div className={cn("flex w-full overflow-ellipsis", popoverContentItemTextClassName, item.value === currentValue && popoverContentSelectedItemTextClassName)}>
                        {item.name}
                      </div>
                    </TooltipTrigger>
                    {!isNil(item.tooltip) && <TooltipContent side="right">{item.tooltip}</TooltipContent>}
                  </SCNTooltip>
                </CommandItem>)}
            </CommandList>
          </CommandGroup>
        </>}
    </Command>;
};
const CommandComboboxVirtual = ({
  data,
  currentValue,
  onSelect,
  setSelectedItem,
  onPopoverOpenChange,
  allowSearch = false,
  customSearchFn,
  splitTextSearch,
  dropdownHeight = 300,
  onSearchKeyDown,
  popoverContentInnerContainerClassName,
  popoverContentItemClassName,
  popoverContentSelectedItemClassName,
  popoverContentItemTextClassName,
  popoverContentSelectedItemTextClassName,
  virtualizerEstimateSize = 35,
  virtualizerGap = 0,
  hideDropdownWhenEmpty = false
}: CommandComboboxProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [filteredItems, setFilteredItems] = useState<ComboItem[]>(data ?? []);
  const [searchText, setSearchText] = useState("");
  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => virtualizerEstimateSize,
    overscan: 5,
    gap: virtualizerGap
  });
  const virtualItems = virtualizer.getVirtualItems();
  const handleSearch = (searchText: string) => {
    const splitSearchText = searchText.split(" ");

    // TODO: when using non-custom search function, make sure that the item is string otherwise this fails
    setFilteredItems(data?.filter(item => customSearchFn ? customSearchFn(item, searchText) : splitTextSearch ? splitSearchText.map(s => (item.name as string).toLowerCase().includes(s.toLowerCase())).every(v => v) : (item.name as string).toLowerCase().includes(searchText.toLowerCase())) ?? []);
  };
  return <Command loop shouldFilter={false} data-sentry-element="Command" data-sentry-component="CommandComboboxVirtual" data-sentry-source-file="combobox.tsx">
      {allowSearch && <CommandInput placeholder="Search..." onValueChange={e => {
      setSearchText(e);
      handleSearch(e);
    }} value={searchText} />}

      {hideDropdownWhenEmpty && filteredItems.length === 0 ? <></> : <>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup style={{
        height: `${dropdownHeight}px`,
        overflow: "hidden"
      }}>
            <CommandList ref={scrollRef} className="max-h-[unset]" style={{
          height: `${dropdownHeight}px`
        }} onWheel={e => {
          e.stopPropagation();
        }} onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (onSearchKeyDown) {
            return onSearchKeyDown(e);
          }
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}>
              <div style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative"
          }} className={cn(popoverContentInnerContainerClassName)}>
                {virtualItems.map(virtualRow => {
              const item = filteredItems[virtualRow.index];
              return <CommandItem key={item.value} value={item.value} onSelect={value => {
                onSelect && onSelect(value);
                setSelectedItem(item);
                onPopoverOpenChange(false);
              }} onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (onSearchKeyDown) {
                  return onSearchKeyDown(e);
                }
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }} data-index={virtualRow.index} ref={virtualizer.measureElement} className={cn("absolute left-0 top-0 flex w-full cursor-pointer justify-between text-component-fg", popoverContentItemClassName, item.value === currentValue && "rounded-sm bg-gray-400 text-primary hover:bg-gray-300", item.value === currentValue && popoverContentSelectedItemClassName)} style={{
                transform: `translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`,
                pointerEvents: "auto",
                opacity: 1
              }}>
                      <SCNTooltip>
                        <TooltipTrigger asChild>
                          <div className={cn("flex w-full overflow-ellipsis", popoverContentItemTextClassName, item.value === currentValue && popoverContentSelectedItemTextClassName)}>
                            {item.name}
                          </div>
                        </TooltipTrigger>
                        {!isNil(item.tooltip) && <TooltipContent side="right" sideOffset={12}>
                            {item.tooltip}
                          </TooltipContent>}
                      </SCNTooltip>
                    </CommandItem>;
            })}
              </div>
            </CommandList>
          </CommandGroup>
        </>}
    </Command>;
};