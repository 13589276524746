import { InputHTMLAttributes, useState, useEffect, RefObject, ComponentProps } from "react";
import { Input as SCNInput } from "@/components/ui/input";
import { Textarea as SCNTextarea } from "@/components/ui/textarea";
type DebouncedInputProps = {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
  textarea?: boolean;
  ref?: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
};
export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  textarea = false,
  ref,
  ...props
}: DebouncedInputProps & Omit<InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement | null>, "onChange">) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);
  return textarea ? <SCNTextarea {...props} ref={ref as RefObject<HTMLTextAreaElement>} value={value} onChange={e => setValue(e.target.value)} data-sentry-element="SCNTextarea" data-sentry-component="DebouncedInput" data-sentry-source-file="debouncedInput.tsx" /> : <SCNInput {...props} ref={ref as RefObject<HTMLInputElement>} value={value} onChange={e => setValue(e.target.value)} data-sentry-element="SCNInput" data-sentry-component="DebouncedInput" data-sentry-source-file="debouncedInput.tsx" />;
};