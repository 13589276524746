"use client";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
type ScrollAreaProps = {
  scrollAreaViewPortClass?: string;
};

// TODO: support the styling classes used here
const ScrollArea = ({
  className,
  children,
  scrollAreaViewPortClass,
  ...props
}: ComponentProps<typeof ScrollAreaPrimitive.Root> & ScrollAreaProps) => <ScrollAreaPrimitive.Root className={cn("relative overflow-hidden", className)} {...props} data-sentry-element="unknown" data-sentry-component="ScrollArea" data-sentry-source-file="scrollArea.tsx">
    <ScrollAreaPrimitive.Viewport className={cn("size-full rounded-[inherit]", scrollAreaViewPortClass)} data-sentry-element="unknown" data-sentry-source-file="scrollArea.tsx">{children}</ScrollAreaPrimitive.Viewport>
    <ScrollBar data-sentry-element="ScrollBar" data-sentry-source-file="scrollArea.tsx" />
    <ScrollAreaPrimitive.Corner data-sentry-element="unknown" data-sentry-source-file="scrollArea.tsx" />
  </ScrollAreaPrimitive.Root>;
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;
const ScrollBar = ({
  className,
  orientation = "vertical",
  ...props
}: ComponentProps<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>) => <ScrollAreaPrimitive.ScrollAreaScrollbar orientation={orientation} className={cn("flex touch-none select-none transition-colors", orientation === "vertical" && "h-full w-2.5 border-l border-l-transparent p-[1px]", orientation === "horizontal" && "h-2.5 flex-col border-t border-t-transparent p-[1px]", className)} {...props} data-sentry-element="unknown" data-sentry-component="ScrollBar" data-sentry-source-file="scrollArea.tsx">
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-gray-500" data-sentry-element="unknown" data-sentry-source-file="scrollArea.tsx" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>;
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;
export { ScrollArea, ScrollBar };