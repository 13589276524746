import { cn } from "@/lib/utils/cssUtils";
import { cva, type VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";
const inputVariants = cva("border-input placeholder:text-placeholder-fg focus-visible:ring-ring text-component-fg flex h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-default disabled:opacity-50", {
  variants: {
    variant: {
      default: "bg-primary",
      form: "cursor-text bg-primary hover:bg-primary-hover focus:bg-primary disabled:cursor-default disabled:bg-primary-hover/50 hover:ring-1 disabled:hover:ring-0"
    },
    defaultVariants: {
      variant: "default"
    }
  }
});
export interface InputProps extends ComponentProps<"input">, VariantProps<typeof inputVariants> {}
const Input = ({
  className,
  variant,
  type,
  ...props
}: InputProps) => {
  return <input type={type} className={cn(inputVariants({
    variant,
    className
  }))} {...props} data-sentry-component="Input" data-sentry-source-file="input.tsx" />;
};
Input.displayName = "Input";
export { Input };