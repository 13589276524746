"use client";

// We can not useState or useRef in a server component, which is why we are
// extracting this part out into it's own file with 'use client' on top
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const makeQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
      }
    }
  });
};
let browserQueryClient: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important so we don't re-make a new client if React
    // supsends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) {
      browserQueryClient = makeQueryClient();
    }
    return browserQueryClient;
  }
};
export const queryClient = getQueryClient();
const QueryLayout = ({
  children
}: PropsWithChildren) => {
  // // NOTE: Avoid useState when initializing the query client if you don't
  // //       have a suspense boundary between this and the code that may
  // //       suspend because React will throw away the client on the initial
  // //       render if it suspends and there is no boundary
  // const queryClient = getQueryClient();

  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryLayout" data-sentry-source-file="queryLayout.tsx">
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" data-sentry-element="ReactQueryDevtools" data-sentry-source-file="queryLayout.tsx" />
    </QueryClientProvider>;
};
export default QueryLayout;