import { format as formatFNS } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Button as SCNButton } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover as SCNPopover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils/cssUtils";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { ClassValue } from "clsx";

// TODO: create a style variant like input, esp for variant `form`

interface DatePickerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  date: Date | undefined | null;
  onDateChange?: (value: Date) => void;
  selectorContainerClassName?: ClassValue;
  calendarPopoverContainerClassName?: ClassValue;
  calendarInnerContainerClassName?: ClassValue;
  selectDatePlaceholderText?: string;
  showYearNavigation?: boolean;
  showDecadeNavigation?: boolean;
  selectableDates?: boolean;
  disabled?: boolean;
  usePortal?: boolean;
  closeOnSelect?: boolean;
}
const DatePicker = ({
  open: openProp,
  onOpenChange,
  date,
  onDateChange,
  selectorContainerClassName,
  calendarPopoverContainerClassName,
  calendarInnerContainerClassName,
  selectDatePlaceholderText,
  showYearNavigation,
  showDecadeNavigation,
  selectableDates,
  disabled,
  usePortal = true,
  closeOnSelect = true
}: DatePickerProps) => {
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: false,
    onChange: onOpenChange
  });
  return <SCNPopover open={open} onOpenChange={setOpen} data-sentry-element="SCNPopover" data-sentry-component="DatePicker" data-sentry-source-file="datePicker.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="datePicker.tsx">
        <SCNButton variant="outline" className={cn("w-[140px] justify-between text-left font-normal hover:ring-1 disabled:hover:ring-0", !date && "text-placeholder-fg", open && "ring-1", selectorContainerClassName)} disabled={disabled} data-sentry-element="SCNButton" data-sentry-source-file="datePicker.tsx">
          {date ? formatFNS(date, "yyyy-MM-dd") : <span>{selectDatePlaceholderText ?? `Pick a date`}</span>}
          <CalendarIcon className="size-4" data-sentry-element="CalendarIcon" data-sentry-source-file="datePicker.tsx" />
        </SCNButton>
      </PopoverTrigger>
      <PopoverContent className={cn("w-auto p-0", calendarPopoverContainerClassName)} align="start" disableArrow usePortal={usePortal} onPointerDownOutside={e => {
      e.stopImmediatePropagation();
    }} data-sentry-element="PopoverContent" data-sentry-source-file="datePicker.tsx">
        <Calendar className={cn("p-2", {
        "px-0": showYearNavigation && showDecadeNavigation || selectableDates
      }, calendarInnerContainerClassName)} mode="single" selected={date ?? undefined} defaultMonth={date ?? undefined} showYearNavigation={showYearNavigation} showDecadeNavigation={showDecadeNavigation} selectableDates={selectableDates} onSelect={e => {
        if (onDateChange && e) {
          onDateChange(e);
          closeOnSelect && setOpen(false);
        }
      }}
      // initialFocus
      data-sentry-element="Calendar" data-sentry-source-file="datePicker.tsx" />
        {onDateChange && <SCNButton variant="ghost" className="border-t-solid flex h-9 w-full items-center justify-center rounded-t-none border-t border-gray-200" onClick={() => {
        onDateChange(new Date());
        closeOnSelect && setOpen(false);
      }}>
            Today
          </SCNButton>}
      </PopoverContent>
    </SCNPopover>;
};
DatePicker.displayName = "DatePicker";
export { DatePicker };